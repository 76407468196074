export default {
    语言设置: '语言设置',
    使用教程: '使用教程',
    其他方式登录: '其他方式登录',
    账号注册: '账号注册',
    返回登录: '返回登录',
    找回密码: '找回密码',
    '想起密码？': '想起密码？',
    去登录: '去登录',
    '微信扫码登录/注册': '微信扫码登录/注册',
    '验证登录/注册': '验证登录/注册',
    请输入手机号: '请输入手机号',
    请输入正确的手机号: '请输入正确的手机号',
    请输入验证码: '请输入验证码',
    秒后重新获取: '%1秒后重新获取',
    获取验证码: '获取验证码',
    未注册手机号将自动创建账号: '未注册手机号将自动创建账号',
    登录: '登录',
    '发送成功,请填写收到的验证码': '发送成功,请填写收到的验证码',
    登录成功: '登录成功',
    账号登录: '账号登录',
    enterPassword: '输入%1到%2位密码',
    '忘记密码？': '忘记密码？',
    确认密码错误: '确认密码错误',
    请确认密码: '请确认密码',
    验证码已发送: '验证码已发送',
    注册成功: '注册成功',
    '重置成功，请登录！': '重置成功，请登录！',
    微信: '微信',
    手机验证: '手机验证',
    账号密码: '账号密码',
    '欢迎您登录印丫云！': '欢迎您登录印丫云！',
    绑定手机号: '绑定手机号',
    '手机号是否绑定新微信？': '手机号是否绑定新微信？',
    '绑定手机号获得更高级的安全保护，同时您可以直接使用手机号登录印路所有产品，方便快捷':
        '绑定手机号获得更高级的安全保护，同时您可以直接使用手机号登录印路所有产品，方便快捷',
    账号已注册: '账号已注册',
    登录或注册即同意: '登录或注册即同意',
    平台服务协议: '平台服务协议',
    隐私政策: '隐私政策',
    和: '和',
    法律声明: '法律声明',
    '欢迎您登录！': '欢迎您登录！'
}
