export default {
    请输入激活码: '请输入激活码',
    退出登录: '退出登录',
    联系客户经理购买激活码: '联系客户经理购买激活码',
    请输入正确的激活码: '请输入正确的激活码',
    绑定成功: '绑定成功',
    更多: '更多',
    印前功能: '印前功能',
    关于印丫云: '关于印丫云',
    退出: '退出',
    请先选择你的项目: '请先选择你的项目'
}
