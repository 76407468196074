import { createI18n } from 'vue-i18n'
import ZH from './zh-CN'
import EN from './en-US'
import config from '@/config'

const messages = {
    zh: { ...ZH },
    en: { ...EN }
}

export default createI18n({
    locale: config?.locale ?? 'zh-CN', // 设置当前语言类型
    legacy: false, // 如果要支持compositionAPI，此项必须设置成false
    globalInjection: true, // 全局注册$t方法
    messages,
    silentTranslationWarn: true,
    missingWarn: false,
    silentFallbackWarn: true,
    fallbackWarn: false
})
