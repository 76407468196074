export default {
    个人信息: '个人信息',
    切换账号: '切换账号',
    个人账号剩余: '个人账号剩余',
    续费: '续费',
    本周上传次数: '本周上传次数',
    头像: '头像',
    名字: '名字',
    手机: '手机',
    邮箱: '邮箱',
    密码: '密码',
    已设置: '已设置',
    已绑定: '已绑定',
    云盘: '云盘',
    请先绑定手机或者邮箱: '请先绑定手机或者邮箱',
    请选择图片上传: '请选择图片上传',
    更新成功: '更新成功',
    重置密码: '重置密码',
    设置密码: '设置密码',
    手机号: '手机号',
    微信: '微信',
    请输入验证码: '请输入验证码',
    请输入密码: '请输入密码',
    秒后重新获取: '%1秒后重新获取',
    获取验证码: '获取验证码',
    enterPassword: '输入%1到%2位密码',
    确认密码错误: '确认密码错误',
    验证码已发送: '验证码已发送',
    设置成功: '设置成功',
    修改名称: '修改名称',
    请输入名字: '请输入名字',
    设置手机号: '设置手机号',
    区号: '区号',
    请选择区号: '请选择区号',
    请输入新的手机号码: '请输入新的手机号码',
    请输入正确的手机号: '请输入正确的手机号',
    修改手机号: '修改手机号',
    设置邮箱: '设置邮箱',
    请输入新的邮箱: '请输入新的邮箱',
    请输入正确的邮箱: '请输入正确的邮箱',
    修改邮箱: '修改邮箱'
}
