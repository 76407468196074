export default {
    必填项: '必填项',

    新建: '新建',
    新增: '新增',
    编辑: '编辑',
    搜索: '搜索',
    删除: '删除',
    批量启用: '批量启用',
    批量禁用: '批量禁用',
    批量删除: '批量删除',
    批量审批: '批量审批',
    详情: '详情',
    绑定: '绑定',
    重置密码: '重置密码',
    设置权限: '设置权限',
    查看: '查看',

    添加: '添加',

    跳过: '跳过',
    展开: '展开',
    收起: '收起',
    登录: '登录',
    确定: '确定',
    提交: '提交',
    修改: '修改',
    预览: '预览',
    下载: '下载',
    上传文件: '上传文件',
    刷新: '刷新',
    警告: '警告',
    确认: '确认',
    取消: '取消',
    已绑定: '已绑定',
    未绑定: '未绑定',
    立即绑定: '立即绑定',
    解除绑定: '解除绑定',
    关闭: '关闭',
    注意: '注意',

    未设置: '未设置',
    已设置: '已设置',
    立即设置: '立即设置',

    '是否确认删除？': '是否确认删除？',
    删除成功: '删除成功',
    更新成功: '更新成功',
    上传成功: '上传成功',
    修改成功: '修改成功',
    绑定成功: '绑定成功',
    添加成功: '添加成功',
    上传失败: '上传失败',

    开启成功: '开启成功',
    关闭成功: '关闭成功',

    天: '天',

    编号: '编号',
    逻辑删除: '逻辑删除',
    乐观锁: '乐观锁',
    创建人id: '创建人id',
    创建人: '创建人',
    创建时间: '创建时间',
    修改人id: '修改人id',
    修改人: '修改人',
    修改时间: '修改时间',
    操作: '操作',
    启用: '启用',
    备注: '备注',
    状态: '状态',

    默认: '默认',
    运行中: '运行中',
    已上线: '已上线',
    异常: '异常',

    禁用: '禁用',
    开: '开',
    关: '关',

    中国大陆: '中国大陆',
    香港: '香港',
    台湾: '台湾',
    澳门: '澳门',

    姓名: '姓名',
    性别: '性别',
    男: '男',
    女: '女',
    开启: '开启',

    地址: '地址',
    是否启用: '是否启用',
    查询: '查询',
    重置: '重置',
    标签: '标签',
    卡盒: '卡盒',
    软包: '软包',
    其它: '其它',

    文件: '文件',
    请输入搜索内容: '请输入搜索内容',
    请选择: '请选择',

    中等: '中等',
    紧凑: '紧凑',
    全选: '全选',
    取消全选: '取消全选',
    '确认是否取消选中？': '确认是否取消选中？',
    时: '时',
    分: '分',
    秒: '秒',
    '登录已过期，请重新登录': '登录已过期，请重新登录',
    '网络请求异常，请稍后重试!': '网络请求异常，请稍后重试!',
    删除目录: '删除目录',
    复制: '复制',
    请输入: '请输入',
    上传: '上传',
    分享: '分享',
    放大: '放大',
    缩小: '缩小',
    返回: '返回',
    项目: '项目',
    设置: '设置',
    用户手册: '用户手册',
    全屏: '全屏',
    取消全屏: '取消全屏',
    '是否确认取消？': '是否确认取消？',
    您的浏览器不支持播放视频: '您的浏览器不支持播放视频',
    您的浏览器不支持播放声音: '您的浏览器不支持播放声音'
}
