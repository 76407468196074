/**
 * 这个是解决在vue组件中动态传入url,标签不会加载资源的问题
 * @param url
 * @returns
 */
export const require = (url: string) => new URL(url.replace('@', '..'), import.meta.url)?.href ?? null

// 手机号隐藏展示
export const maskPhoneNumber = value => {
    if (value && /^\d{11}$/.test(value)) {
        return value.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
    }
    return value
}

// 邮箱隐藏展示
export const maskEmailAddress = value => {
    if (value && /(.{3}).*(@.*)/.test(value)) {
        return value.replace(/(.{3}).*(@.*)/, '$1****$2')
    }
    return value
}

// 获取文件后缀名
export function getExtension(name: string) {
    return name ? name.substring(name.lastIndexOf('.')) : ''
}

export function download(url: string, filename = '') {
    // window.open(url);

    let a = document.createElement('a')
    a.style.display = 'none'
    if (filename != '') {
        a.download = filename
        // a.setAttribute('download', decodeURI(filename));
    }
    a.href = url
    document.body.appendChild(a)
    a.click() // 触发a标签的click事件
    document.body.removeChild(a)
}

// 获取地址栏参数
export const getQueryString = name => {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    let r = window.location.search.substring(1).match(reg)
    if (r != null) return decodeURIComponent(r[2])
    return null
}

// 根据url获取图片的宽高
export function getImageSize(url) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = url

        img.onload = function () {
            resolve({ width: img?.width ?? 0, height: img?.height ?? 0 })
        }

        img.onerror = function () {
            reject(new Error('Failed to load image'))
        }
    })
}

// 判断是否是移动端
export function isMobile() {
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
    return !(navigator.userAgent.match(mobileRegex) == null)
}

/**
 * 字符串占位符替换
 * 示例使用：
 * const template = "This is %1 placeholder, and this is %2 placeholder. %3 is not replaced.";
 * const result = replaceformat(template, 'first', 'second');
 * console.log(result);
 * 输出: This is first placeholder, and this is second placeholder. %3 is not replaced.
 * @param template
 * @param replacements
 * @returns
 */
export function replaceformat(template, ...replacements) {
    // 匹配形如 %1、%2 等的占位符
    const placeholderRegex = /%(\d+)/g
    let match
    let result = template

    // 使用循环和正则表达式的exec方法迭代地替换占位符
    while ((match = placeholderRegex.exec(result)) !== null) {
        const placeholderIndex = parseInt(match[1], 10) - 1 // 将占位符中的数字转换为索引

        // 检查索引是否在替换值的范围内
        if (placeholderIndex >= 0 && placeholderIndex < replacements.length) {
            // 替换占位符为对应的替换值
            const replacement = replacements[placeholderIndex]
            result = result.replace(match[0], replacement)
        }
        // 如果索引超出范围，则占位符保持不变
    }

    return result
}
