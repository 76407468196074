export default {
    历史记录: '历史记录',
    没有更多了: '没有更多了',
    原件: '原件',
    生成文件: '生成文件',
    不支持该类型文件查看: '不支持该类型文件查看',
    该文件无法查看: '该文件无法查看',
    处理中: '处理中',
    已完成: '已完成',
    失败: '失败',
    成功: '成功',
    已超时: '已超时',
    审核通过: '审核通过',
    审核拒绝: '审核拒绝',
    流程终止: '流程终止',
    已创建: '已创建',
    发送成功: '发送成功'
}
