import { createApp } from 'vue'
import Vant from 'vant'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import 'vant/lib/index.css'
import './assets/css/main.css'

import App from './App.vue'
import router from './router'
import i18n from './lang'
import store from './stores'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(i18n)
app.use(Vant)
app.use(Antd)
app.mount('#app')
