export default {
    请输入激活码: 'Please enter the activation code Please enter the activation code',
    退出登录: 'Log out',
    联系客户经理购买激活码: 'Contact the account manager to purchase an activation code',
    请输入正确的激活码: 'Please enter the correct activation code',
    绑定成功: 'Binding successful',
    更多: 'more',
    印前功能: 'Prepress function',
    关于印丫云: 'About the Yin Ya Cloud',
    退出: 'quit',
    请先选择你的项目: 'Please select your project first'
}
