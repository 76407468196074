export default {
    历史记录: 'Historical record',
    没有更多了: 'No more',
    原件: 'Original copy',
    生成文件: 'Generate file',
    不支持该类型文件查看: 'This type of file cannot be viewed',
    该文件无法查看: 'Changed file cannot be viewed',
    处理中: 'doing',
    已完成: 'done',
    失败: 'fail',
    成功: 'success',
    已超时: 'timeout',
    审核通过: 'audit pass',
    审核拒绝: 'audit rejection',
    流程终止: 'Process termination',
    已创建: 'create',
    发送成功: 'send success'
}
