export enum ContentTypeEnum {
    // json
    JSON = 'application/json;charset=UTF-8',
    // form-data   上传资源（图片，视频）
    FORM_DATA = 'multipart/form-data'
}

export enum RequestMethodsEnum {
    GET = 'GET',
    POST = 'POST'
}

export enum RequestCodeEnum {
    TOKEN_EMPTY = 201001, // TOKEN参数为空
    TOKEN_INVALID = 201002, // TOKEN参数无效

    OK = 0, // 成功
    ERROR = 101000, // ERROR
    ERR_MAINTAIN = 101001, // 系统维护
    ERR_PERMISSION_DENIED = 101002, // 权限不足
    ERR_MISSING_VERSION = 101003, // 不存在该版本
    ERR_MUST_UPGRADE = 101004, // 强制更新
    ERR_HIGH_FREQUENCY_ACCESS = 101005, // 访问频率过高
    ERR_EXCEED_LIMIT = 101006, // 超出限制
    ERR_NO_PROTO = 101008, // 协议不存在
    ERR_SERVER_CRASH = 101009, // 无法连接服务器，请稍后再试
    ErrRequireToken = 201001, // 请求未携带token，无权限访问
    ErrTokenTimeout = 201002, // token授权已过期，请重新申请授权
    ErrArgError = 201003, // 参数错误
    ERR_NOT_IN_SIZE = 201004, // 参数不在范围内
    ERR_BAD_SIGN = 201005, // 签名不正确
    ERR_ILLEGAL_CHATS = 201006, // 参数中拥有非法字符
    ERR_SENSITIVE_CHARACTER = 201007, // 含有敏感字符
    ERR_MAX_SIZE = 201008, // ?KEY不能超过
    ERR_MIN_SIZE = 201009, // ?KEY不能少于
    ERR_ATTR_NOT_ENOUGH_NUM = 201010, // 没有足够的数量
    ERR_NOT_BINARY = 201011, // 参数需要是字符串
    ERR_PAGE_MAX_SIZE = 201012, // 页数超过最大限制
    ERR_PAGE_SIZE_ERR = 201013, // 页数需要大于等于0
    ErrDataExit = 201014, // 数据已经存在
    ErrDataNotFound = 201017, // 数据不存在
    ErrEncode = 201015, // encode失败
    ErrDecode = 201016, // decode失败
    ERRExit = 201017, // 数据已存在
    ERRNotFound = 201018, // 数据不存在
    ErrMySQLSqlExecErr = 301001, // 【MySQL】SQL执行失败
    ErrMySQLConnErr = 301002, // 【MySQL】连接失败
    ErrRedisExecErr = 301003, // 【Redis】Redis执行失败
    ErrRedisConnErr = 301004, // 【Redis】连接失败
    ErrMinioGetTokenErr = 301005, // 【minio】获取token失败
    ErrMinioCheckTokenErr = 301006, // 【minio】检查token失败
    ErrMinioUploadTokenErr = 301007, // 【minio】上传文件失败
    ErrFileNotFound = 401001, // 文件不存在
    ErrFileGetTokenErr = 401002, // 【File】获取token失败
    ErrFileCheckTokenErr = 401003, // 【File】检查token失败
    ErrFileExit = 401004, // 文件已存在
    ErrFileDownloadFail = 401005, // 文件下载失败，请稍后再试！
    ErrFileUploadFail = 401006, // 文件上传失败，请稍后再试！
    ErrSDKCallFail = 901001, // sdk调用失败
    ErrSDKArgError = 902001, // 参数错误
    ErrSDKBindingError = 903001, // 绑定失败，请稍后再试
    ErrPasswordErr = 501001, // 密码错误
    ErrUserDisable = 501002, // 用户被禁用
    ErrUserExit = 501003, // 账号已存在
    ErrMobileExit = 501004, // 手机号已存在
    ErrEmailExit = 501005, // 邮箱已存在
    ErrUserNotFound = 501006, // 用户不存在
    ErrRoleExit = 501007, // 角色已存在
    ErrRoleNotFound = 501008, // 角色不存在
    ErrMenuExit = 501009, // 菜单已存在
    ErrMenuNotFound = 501010, // 菜单不存在
    ErrEnterpriseMenuExit = 501011, // 客户已存在
    ErrEnterpriseNotFound = 501012, // 客户不存在
    ErrCodeIsDifferent = 501012, // 验证码错误
    ErrUserTimeout = 501013, // 账号已过期
    ErrCDKeyNotFound = 501014, // 激活码无效
    ErrCDKeyUsed = 501015, // 激活码已被使用
    ErrMobileBinding = 501016, // 已绑定手机号
    ErrMobileBindingOther = 501017, // 手机号已被使用
    ErrEmailBinding = 501018, // 已绑定邮箱
    ErrEmailBindingOther = 501019, // 邮箱已被使用
    ErrDictExit = 502001, // 字典已存在
    ErrDictNotFound = 502002, // 字典不存在
    ErrConfigExit = 502003, // 数据已存在
    ErrConfigNotFound = 502004, // 数据不存在
    SharedNeedPassworld = 30103001, // 需要密码
    SharedExpired = 30103002 // 分享链接过时
}
