export default {
    个人信息: 'Personal information',
    切换账号: 'Switch account',
    个人账号剩余: 'Personal account surplus',
    续费: 'renew',
    本周上传次数: 'Number of uploads this week',
    头像: 'avatar',
    名字: 'name',
    手机: 'cellphone',
    邮箱: 'mailbox',
    密码: 'password',
    已设置: 'Have set',
    已绑定: 'Bound',
    云盘: 'Cloud disk',
    请先绑定手机或者邮箱: 'Please bind your mobile phone or email first',
    请选择图片上传: 'Please select image upload',
    更新成功: 'Update successfully',
    重置密码: 'Reset password',
    设置密码: 'Set a password',
    手机号: 'Mobile phone number',
    微信: 'wechat',
    请输入验证码: 'Please enter the verification code',
    请输入密码: 'Please enter the password',
    秒后重新获取: 'Retrieve it in %1 seconds',
    获取验证码: 'Get verification code',
    enterPassword: 'Enter a password of %1 to 2% digits',
    确认密码错误: 'The confirmation password entered is incorrect',
    验证码已发送: 'Verification code has been sent',
    设置成功: 'Set successfully',
    修改名称: 'Change Name',
    请输入名字: 'Please enter your first name',
    设置手机号: 'Set phone number',
    区号: 'Area code',
    请选择区号: 'Please select the area code',
    请输入新的手机号码: 'Please enter a new phone number',
    请输入正确的手机号: 'Please enter the correct phone number',
    修改手机号: 'Change phone number',
    设置邮箱: 'Set up email',
    请输入新的邮箱: 'Please enter a new email address',
    请输入正确的邮箱: 'Please enter the correct email address',
    修改邮箱: 'Modify email'
}
