<script setup lang="ts">
import { watchEffect, onMounted } from 'vue'
import { RouterView } from 'vue-router'
import { Locale } from 'vant'
import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'
import aenUS from 'ant-design-vue/es/locale/en_US'
import azhCN from 'ant-design-vue/es/locale/zh_CN'
import { storeToRefs } from 'pinia'
import { useLanguageStore } from '@/stores/language'
import { useChannelAppStore } from '@/stores/channelApp'
import { isMobile } from '@/utils'
const languagestore = useLanguageStore()
const { locale } = storeToRefs(languagestore)
const channelAppStore = useChannelAppStore()

const printroadPrefixArr = ['mobile-prepress', 'test-mobile-prepress', 'dev-mobile-prepress']
const getPrefix = (prefix?: string) => (prefix && !printroadPrefixArr.includes(prefix) ? prefix + '.' : '')

const appResize = (prefix?: string) => {
    if (!isMobile() && import.meta.env.MODE !== 'dev') {
        if (import.meta.env.MODE === 'production') {
            window.location.replace(`https://${getPrefix(prefix)}prepress.printroad.cn`)
        } else if (import.meta.env.MODE === 'test') {
            window.location.replace(`https://${getPrefix(prefix)}test-prepress.printroad.cn`)
        } else {
            window.location.replace(`https://${getPrefix(prefix)}dev-prepress.printroad.cn`)
        }
    }
}

watchEffect(() => {
    Locale.use(locale.value, locale.value === 'en-US' ? enUS : zhCN)
})

// https://123abc.mobile-prepress.printroad.cn/login
// https://123abc.test-mobile-prepress.printroad.cn/login
onMounted(() => {
    const hostname = window?.location?.hostname ?? ''
    const prefix = hostname.split('.')?.[0] ?? ''
    appResize(prefix)
    if (prefix) {
        channelAppStore.onload(prefix)
    } else {
        channelAppStore.setChannelApp({})
    }
})
</script>

<template>
    <a-config-provider :theme="{ token: { colorPrimary: '#00baab' } }" :locale="locale === 'en-US' ? aenUS : azhCN">
        <RouterView />
    </a-config-provider>
</template>

<style>
.van-nav-bar__title {
    font-weight: normal;
    font-size: 13px;
}
</style>
