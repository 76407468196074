import login from './login'
import component from './component'
import message_code from './message_code'
import yyy from './yyy'
import p404 from './404'
import home from './home'
import history from './history'
import file from './file'
import account from './account'
import skill from './skill'

export default {
    ...login,
    ...component,
    ...message_code,
    ...yyy,
    ...home,
    ...p404,
    ...history,
    ...file,
    ...account,
    ...skill
}
