import { request } from '@/libs/axios'
import type { ResponseData } from '@/libs/axios/baseType'

// 获取功能列表
export async function get_skill_list(params: any) {
    return await request.post<ResponseData<any>>('/skill/list', params)
}

export async function file_list(params: any) {
    return request.post<ResponseData<any>>('/file/list_resources', params)
}

// 查询渠道商的自定义入口信息
export async function channel_app_query(param: any) {
    return await request.post<ResponseData<any>>('/3/t_user/channel_app/queryByAppNamespacePrefix', param)
}

// 获取客户端配置接口
export async function client_config_list(param?: any) {
    return await request.post<ResponseData<any>>('/3/t_config/client-config/list', param)
}
