import { CONFIG_DATA_KEY } from '@/enums/cacheEnums'
import defaultConfig from './'
import cache from '@/libs/cache'

type Config = {
    Dict: Record<string, Record<string, any>>
    ClientConfig: Record<string, any>
    Language: Record<string, any>
}

export const config: Config = {
    Dict: {},
    ClientConfig: {},
    Language: {}
}

export enum languageTypeEnum {
    page = 'page',
    code = 'code'
}

export enum localeEnum {
    locale_zh = 'zh-CN',
    locale_en = 'en-US'
}

export function initConfigData(key: string) {
    var data = cache.get(CONFIG_DATA_KEY + key)
    if (data != '' && data != undefined) {
        switch (key) {
            case 'client_config':
                for (var i = 0; i < data.length; i++) {
                    config.ClientConfig[data[i][0]] = data[i][1]
                }
                break
            case 'language':
                for (var i = 0; i < data.length; i++) {
                    config.Language[data[i][0] + data[i][1] + localeEnum.locale_zh] = data[i][2]
                    config.Language[data[i][0] + data[i][1] + localeEnum.locale_en] = data[i][3]
                }
                break
            case 'dict':
                config.Dict = data
                break
        }
    }
}

export function getDict(key: string, subKey: string): any {
    var subDict = config.Dict[key]
    return subDict[subKey]
}

export function getClientConfig(key: string): any {
    return config.ClientConfig[key]
}

export function getLanguageValue(key: string, languageType: string): any {
    return config.Language[key + languageType + defaultConfig.locale] //接入多语言后，要传当前语种
}
