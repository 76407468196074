import { createRouter, createWebHistory } from 'vue-router'
import { TOKEN_KEY } from '@/enums/cacheEnums'
const Layout = () => import('@/layout/index.vue')
const Login = () => import('@/views/login/index.vue')
const Home = () => import('@/views/home/index.vue')
const Skill = () => import('@/views/skill/index.vue')
const History = () => import('@/views/history/index.vue')
const HistoryDetail = () => import('@/views/history/detail.vue')
const File = () => import('@/views/file/index.vue')
const Cloudflow = () => import('@/views/cloudflow/index.vue')
const Account = () => import('@/views/account/index.vue')
const PreCheck = () => import('@/views/handlerFile/preCheck/index.vue')
const Trapping = () => import('@/views/handlerFile/trapping/index.vue')
const Makeup = () => import('@/views/handlerFile/makeup/index.vue')
const RIP = () => import('@/views/handlerFile/RIP/index.vue')
const Approve = () => import('@/views/handlerFile/approve/index.vue')
const Flexography = () => import('@/views/handlerFile/flexography/index.vue')
const Blends = () => import('@/views/handlerFile/blends/index.vue')
const ImageEnhancement = () => import('@/views/handlerFile/imageEnhancement/index.vue')
const Conversion = () => import('@/views/handlerFile/conversion/index.vue')
const VDP = () => import('@/views/handlerFile/VDP/index.vue')
const SoftRoll = () => import('@/views/handlerFile/softRoll/index.vue')
const DaomuMakeup = () => import('@/views/handlerFile/daomuMakeup/index.vue')
const Tiff = () => import('@/views/handlerFile/tiff/index.vue')
const TextGeneratedPicture = () => import('@/views/handlerFile/textGeneratedPicture/index.vue')
const AutomaticBleeding = () => import('@/views/handlerFile/automaticBleeding/index.vue')
const Nickname = () => import('@/views/settingAccount/nickname/index.vue')
const Mobile = () => import('@/views/settingAccount/mobile/index.vue')
const BindMobile = () => import('@/views/settingAccount/mobile/bindMobile.vue')
const Email = () => import('@/views/settingAccount/email/index.vue')
const BindEmail = () => import('@/views/settingAccount/email/bindEmail.vue')
const Password = () => import('@/views/settingAccount/password/index.vue')
const Yyy = () => import('@/views/yyy.vue')
const NotFound = () => import('@/views/404.vue')

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/',
            component: Layout,
            children: [
                {
                    path: '/',
                    redirect: '/home'
                },
                {
                    path: '/home',
                    name: 'home',
                    component: Home,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/skill',
                    name: 'skill',
                    component: Skill,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/history',
                    name: 'history',
                    component: History,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/detail',
                    name: 'detail',
                    component: HistoryDetail,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/file',
                    name: 'file',
                    component: File,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/cloudflow',
                    name: 'cloudflow',
                    component: Cloudflow,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/account',
                    name: 'account',
                    component: Account,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/PreCheck',
                    name: 'PreCheck',
                    component: PreCheck,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/Trapping',
                    name: 'Trapping',
                    component: Trapping,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/Makeup',
                    name: 'Makeup',
                    component: Makeup,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/RIP',
                    name: 'RIP',
                    component: RIP,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/Approve',
                    name: 'Approve',
                    component: Approve,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/Flexography',
                    name: 'Flexography',
                    component: Flexography,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/Blends',
                    name: 'Blends',
                    component: Blends,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/ImageEnhancement',
                    name: 'ImageEnhancement',
                    component: ImageEnhancement,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/Conversion',
                    name: 'Conversion',
                    component: Conversion,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/VDP',
                    name: 'VDP',
                    component: VDP,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/SoftRoll',
                    name: 'SoftRoll',
                    component: SoftRoll,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/DaomuMakeup',
                    name: 'DaomuMakeup',
                    component: DaomuMakeup,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/Tiff',
                    name: 'Tiff',
                    component: Tiff,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/TextGeneratedPicture',
                    name: 'TextGeneratedPicture',
                    component: TextGeneratedPicture,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/AutomaticBleeding',
                    name: 'AutomaticBleeding',
                    component: AutomaticBleeding,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/nickname',
                    name: 'nickname',
                    component: Nickname,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/mobile',
                    name: 'mobile',
                    component: Mobile,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/bindMobile',
                    name: 'bindMobile',
                    component: BindMobile,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/email',
                    name: 'email',
                    component: Email,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/bindEmail',
                    name: 'bindEmail',
                    component: BindEmail,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/password',
                    name: 'password',
                    component: Password,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                }
                // 其他页面路由配置
            ]
        },
        {
            path: '/yyy',
            name: 'yyy',
            component: Yyy,
            meta: {
                requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
            }
        },
        {
            path: '/:pathMatch(.*)*', // 匹配所有路径
            name: '404',
            component: NotFound // 渲染自定义的 404 页面组件
        }
    ]
})

// 导航守卫
router.beforeEach((to, _, next) => {
    const isLoggedIn = Boolean(window.localStorage.getItem(TOKEN_KEY)) // 判断用户是否已登录
    if (to.meta.requireAuth && !isLoggedIn) {
        // 需要验证登录状态，并且未登录
        next('/login') // 跳转到登录页
    } else if (to.path === '/login') {
        if (isLoggedIn) {
            // 如果用户已登录并访问登录页面，则自动跳转到首页
            next('/home')
        } else {
            window.localStorage.clear()
            next()
        }
    } else {
        next() // 其他情况放行
    }
})

export default router
