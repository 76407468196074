import { reactive } from 'vue'
import { defineStore } from 'pinia'
import { channel_app_query } from './service'
import { RequestCodeEnum } from '@/enums/requestEnums'

export const useChannelAppStore = defineStore('channelAppStore', () => {
    const channelApp = reactive<any>({})
    const setChannelApp = data => {
        Object.assign(channelApp, data)
    }

    const onload = async prefix => {
        const res = await channel_app_query({ app_namespace_prefix: prefix })
        if (res.code === RequestCodeEnum.OK) {
            setChannelApp(res?.data ?? {})
            if (res?.data?.app_name) {
                document.title = res?.data?.app_name
            }
        }
    }

    return { channelApp, setChannelApp, onload }
})
